


















import { PropType } from 'vue'
import ToolTip from './ToolTip.vue'
import TextInput from './TextInput.vue'

export default TextInput.extend({
  components: {
    ToolTip,
  },
  props: {
    options: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
})
